import { useState } from "react"
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import "./style.scss"

function About () {
    const [letterClass] = useState("text-animate")

    return (
        <div>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={["A", " ", "p", "r", "o", "p", "o", "s"]}
                            idx={15}
                        />
                    </h1>
                    <p>
                        Je suis un développeur web junior, désireux de mettre mes compétences
                        en pratique et de continuer à apprendre au sein d'un environnement professionel.
                        Je suis à la recherche d'une opportunité au sein d'une entreprise où je pourrais
                        non seulement appliquer mes connaissances, mais aussi évoluer en tant que 
                        développeur.
                    </p>
                    <p align="LEFT">
                        Mon parcours m'a permis de maîtriser les base du développement web, comme le HTML,
                        CSS, Javascript et des frameworks modernes comme React. Cependant, je suis conscient
                        qu'il faut compléter cette théorie acquise avec de l'expérience sur des projets réels.
                        C'est pour cela que je suis motivé à rejoindre une équipe pour des projets diversifiés,
                        relever des défis techniques et bénéficier de l'expérience de collègues.
                    </p> 
                    <p>
                        Si je dois me définir en une phrase, je dirais que je suis passioné par les sports d'équipes, 
                        toujours curieux des dernières innovations technologiques, serviable et j'aime aider 
                        les autres.
                    </p>
                </div> 

                <div className="cube-cont">
                    <div className="cube-spinner">
                        <div className="face1 face">
                            <i className="fa-brands fa-html5"></i>
                        </div>

                        <div className="face2 face">
                            <i className="fa-brands fa-css3-alt"></i>
                        </div>

                        <div className="face3 face">
                            <i className="fa-brands fa-square-js"></i>
                        </div>

                        <div className="face4 face">
                            <i className="fa-brands fa-react"></i>
                        </div>

                        <div className="face5 face">
                            <i className="fa-brands fa-git-alt"></i>
                        </div>

                        <div className="face6 face">
                            <i className="fa-brands fa-figma"></i>
                        </div>
                    </div>
                </div>
            </div>

            <Loader type="pacman" active className="loader-custom" />
        </div>
    )
}

export default About