import { useState } from "react"
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import Cloud  from "./Cloud"
import "./style.scss"

function Skills () {
    const [letterClass] = useState("text-animate")

    const skills = [
        {
            title: "Intégration web HTML - CSS",
            description: "Conversion de maquette figma en code HTML & CSS."
        },
        {
            title: "Gestion de projet et outils de développeurs",
            description: "Compréhension des méthodologies allant de la planification à la livraison d'un site."
        },
        {
            title: "Optimisation et débug",
            description: "Utilisation des DevTools afin d'optimiser un site web."
        },
        {
            title: "Utilisation de Javascript",
            description: "Récupération de données, manipulation du DOM et gestion des événements utilisateur."
        },
        {
            title: "Utilisation de React",
            description: "Initialisation d'une app React et utilisation de diverses composants."
        },
        {
            title: "Développement back-end avec NodeJS",
            description: "Mise en oeuvre des opérations CRUD et stockage de données de façon sécurisé."
        },
        {
            title: "Publication d'un site web",
            description: "Déployer un site en ligne."
        }
    ]

    return (
        <div>
            <div className="container skills-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={["M", "e", "s", " ", 
                                "c", "o", "m", "p", "é", "t", "e", "n", "c", "e", "s"]}
                            idx={15}
                        />
                    </h1>

                    <ul className="list-skills">
                        {skills.map((skill, index) => (
                            <li key={index} className="list-skills">
                                <h2>{skill.title}</h2>
                                <p>{skill.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                    <Cloud />
            </div>

            <Loader type="pacman" active className="loader-custom" />
        </div>
    )
}

export default Skills