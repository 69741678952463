import { useState } from "react"
import Loader from "react-loaders"
import { useRef } from "react"
import emailjs from "@emailjs/browser"
import AnimatedLetters from "../AnimatedLetters"
import Modal from "./Modal"
import "./style.scss"

function Contact () {
    const [letterClass] = useState("text-animate")
    const form = useRef()
    const [modalMessage, setModalMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const closeModal = () => {
        setModalMessage(null)
        form.current.reset()
    }

    const sendEmail = (e) => {
        e.preventDefault()
        setLoading(true)

        console.log("Envoi de l'email en cours...");

        emailjs
            .sendForm(
                "service_g5wemcf",
                "template_agy0ns4",
                form.current,
                "QGL36A4mRoOXXxtS2"
            )
            .then(
                () => {
                    setLoading(false)
                    setModalMessage({ title: "Succès!", text: "Message envoyé avec succès!" });
                },
                (error) => {
                    setLoading(false)
                    console.error("Erreur lors de l'envoi:", error);
                    setModalMessage({ title: "Erreur!", text: "Erreur lors de l'envoi, veuillez réessayer" });
                }
            )
    }

    return (
        <div>
            <div className="container contact-page">
                <div className="contact-text-zone">
                    <h1>
                        <AnimatedLetters
                        letterClass={letterClass} 
                        strArray={["C", "o", "n", "t", "a", "c", "t"]}
                        idx={15}
                        />
                    </h1>

                    <div className="contact-form">
                        <form ref={form} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input placeholder="Nom" type="text" name="nom" required />
                                </li>
                                <li className="half">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </li>

                                <li>
                                    <input
                                        placeholder="Sujet"
                                        type="text"
                                        name="sujet"
                                        required
                                    />
                                </li>

                                <li>
                                    <textarea
                                        placeholder="Message"
                                        name="message"
                                        required
                                    />
                                </li>

                                <li>
                                    <input type="submit" className="flat-button" value="ENVOYER" disabled={loading}/>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>

            {loading && (
                <div className="loader-overlay">
                    <Loader type="pacman" active className="loader-custom" />
                </div>
            )}

            {modalMessage && <Modal message={modalMessage} onClose={closeModal} />}

            <Loader type="pacman" active className="loader-custom" />
        </div>
    )
}

export default Contact