import { useState } from "react"
import Logo from "../../assets/images/logo.png"
import { Link, NavLink } from "react-router-dom"
import "./style.scss"

function Sidebar () {
    const [showNav, setShowNav] = useState(false);

    return (
        <div className="nav-bar">
            <Link className="logo" to="/" onClick={() => setShowNav(false)}>
                <img src={Logo} alt="Logo" />
            </Link>
            <nav className={showNav ? "mobile-show" : ""}>
                <NavLink exact="true" activeclassname="active" to="/" onClick={() => setShowNav(false)} data-text="ACCUEIL">
                    <i className="far fa-house"></i>
                </NavLink>

                <NavLink activeclassname="active" className="about-link" to="/about" onClick={() => setShowNav(false)} data-text="A PROPOS">
                    <i className="far fa-user"></i>
                </NavLink>

                <NavLink activeclassname="active" className="skills-link" to="/skills" onClick={() => setShowNav(false)} data-text="APTITUDES">
                    <i className="far fa-head-side-gear"></i>
                </NavLink>

                <NavLink activeclassname="active" className="project-link" to="/project" onClick={() => setShowNav(false)} data-text="PROJETS">
                    <i className="far fa-eye"></i>
                </NavLink>

                <NavLink activeclassname="active" className="contact-link" to="/contact" onClick={() => setShowNav(false)} data-text="CONTACT">
                    <i className="far fa-envelope"></i>
                </NavLink>

                
            </nav>

            <ul>
                <li>
                    <a href="https://www.linkedin.com/in/romain-goujon-970518260/" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-linkedin anchor-icon"></i>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/RomainGoujon" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-github anchor-icon"></i>
                    </a>
                </li>
            </ul>
            
            <i className={`fa-regular ${showNav ? "fa-xmark close-icon" : "fa-bars burger-icon"}`} onClick={() => setShowNav(!showNav)}></i>
        </div>
    )
}

export default Sidebar