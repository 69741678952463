import { useEffect, useRef } from "react"
import "./style.scss"

function Cloud () {
    const canvasRef = useRef(null);

    const getRandomSpeed = (min, max) => {
        return Math.random() * (max - min) + min;
    };

    useEffect(() => {
        try {
            window.TagCanvas.Start("myCanvas", "skills-tags", {
                textColour: "#dbddd8",
                outlineColour: "transparent",
                reverse: false,
                depth: 0.8,
                maxSpeed: 0.08,
                zoom: 0.85,
                initial: [getRandomSpeed(-0.1, 0.1), getRandomSpeed(-0.1, 0.1)],
                textFont: "Roboto, sans-serif",
                textHeight: 24
            });
 
        } catch (e) {
            console.log("TagCanvas erreur:", e)
        }
    }, []);

    return (
        <div className="cloud-container">
            <canvas ref={canvasRef} id="myCanvas" width={500} height={500}>
                <ul id="skills-tags" style={{ display: "none" }}>
                    <li><a href="#">Google Lighthouse</a></li>
                    <li><a href="#">JavaScript</a></li>
                    <li><a href="#">React</a></li>
                    <li><a href="#">HTML</a></li>
                    <li><a href="#">CSS</a></li>
                    <li><a href="#">Angular</a></li>
                    <li><a href="#">Node.js</a></li>
                    <li><a href="#">MongoDB</a></li>
                    <li><a href="#">Sass</a></li>
                    <li><a href="#">GIMP</a></li>
                    <li><a href="#">Git</a></li>
                    <li><a href="#">NPM</a></li>
                    <li><a href="#">Figma</a></li>
                    <li><a href="#">JSX</a></li>
                    <li><a href="#">TypeScript</a></li>
                    <li><a href="#">JSON</a></li>
                    <li><a href="#">SEO</a></li>
                </ul>
            </canvas>
        </div>
    )
}

export default Cloud