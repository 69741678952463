import React, { useState } from "react"
import Loader from "react-loaders"
import AnimatedLetters from "../AnimatedLetters"
import projects from "../../data/project.json"
import Modal from "./Modal"
import "./style.scss"

function Project () {
    const [letterClass] = useState("text-animate")
    const [activeModal, setActiveModal] = useState(null);

    const openModal = (id) => {
        setActiveModal(id)
    }

    const closeModal = () => {
        setActiveModal(null)
    }

    return (
        <div>
            <div className="container project-page">
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={["P", "r", "o", "j", "e", "t", "s"]}
                        idx={15}
                    />
                </h1>

                <div className="grid">
                    {projects.map((project) => (
                        <div className="grid-item" key={project.id}>
                            <div className="card" onClick={() => openModal(project.id)}>
                                <img 
                                    src={project.images[0].src}
                                    alt={project.images[0].alt}
                                    loading="lazy"
                                    className="card-image"
                                />

                                <div className="card-overlay"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {activeModal && (
                <Modal 
                    project={projects.find(p => p.id === activeModal)} 
                    onClose={closeModal} 
                />
            )}

            <Loader type="pacman" active className="loader-custom" />
        </div>
    )
}

export default Project