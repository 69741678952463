import React from "react"
import "./style.scss"

const Modal = ({ message, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{message.title}</h2>
                <p>{message.text}</p>
                <button onClick={onClose} className="modal-button">OK</button>
            </div>
        </div>
    );
};

export default Modal;
